/* Responsive menu */
const btnMenu = document.querySelector("#toggle-nav")
btnMenu.addEventListener("click", toggleMenu)

function toggleMenu(e){
    document.querySelector("#navegacion").classList.toggle("mostrar")
}

/* Smooth scroll */
function scrollNav () {
    const enlaces = document.querySelectorAll(".navegacion .inner")
    enlaces.forEach(enlace => {
        enlace.addEventListener("click", function (e) {
            e.preventDefault();
            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({ behavior:"smooth" })
        });
    });
}

scrollNav();

/* Scroll hacia arriba */
const volverArriba = document.querySelector("#volver-arriba")
volverArriba.addEventListener("click", () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
})

/* Contacto */
const contactoSubmit = document.querySelector("#contactoSubmit")
contactoSubmit.addEventListener("click", enviarContacto)

async function enviarContacto (e) {
    e.preventDefault()
    const nombre = document.querySelector("#nombre").value
    const ayuntamiento = document.querySelector("#ayuntamiento").value
    const email = document.querySelector("#email").value
    const telefono = document.querySelector("#telefono").value
    const mensaje = document.querySelector("#mensaje").value

    //Validar campos
    if([nombre, email, mensaje].includes("")){
        console.log("error hay campos vacios")
        return;
    }

    //Crear un objeto con los inputs
    const datos = new FormData();
    
    datos.append("nombre", nombre);
    datos.append("ayuntamiento", ayuntamiento);
    datos.append("email", email );
    datos.append("telefono", telefono);
    datos.append("mensaje", mensaje);

    //Petición a la API
    try {
        await fetch("/contacto.php", {
            method: "POST",
            body: datos
        })

        //Mostrar mensaje de exito
        Swal.fire({
            title: "Enviat!",
            text: "El teu missatge s'ha enviat correctament",
            icon: "success",
            confirmButtonColor: '#E91075',
            confirmButtonText: "Gràcies"
        })

    } catch (error) {
        console.log(error)
    }
}

// Cookies
(function () {
    "use strict";

    var cookieAlert = document.querySelector(".cookie-alert");
    var acceptCookies = document.querySelector(".accept-cookies");

    cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

    if (!getCookie("acceptCookies")) {
        cookieAlert.classList.add("show");
    }

    acceptCookies.addEventListener("click", function () {
        setCookie("acceptCookies", true, 60);
        cookieAlert.classList.remove("show");
    });
})();

// Cookie functions stolen from w3schools
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}